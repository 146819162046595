import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

interface BrauschrittExpiredDialogProps {
    onOpenClose: (open: boolean) => void;
    isOpen: boolean;
    brauschrittName?: string;
}

export default function BrauschrittExpiredDialog(props: BrauschrittExpiredDialogProps): React.ReactElement {

    const handleClose = () => {
        props.onOpenClose(false);
    };

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={() => handleClose()}
            >
                <DialogTitle id="alert-dialog-title">Brauschritt abgelaufen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.brauschrittName || "Der Brauschritt"} ist abgelaufen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>

            </Dialog>
        </div>
    );

}
