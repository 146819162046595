import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useState, useEffect } from "react";
import CardButton from "../Common/CardButton";

const useStyles = makeStyles({
    imgResponsive: {
        width: '100%',
        height: 'auto'
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '2em'
    },
    buttonBox: {
        marginTop: '20px',
        marginBottom: '30px'
    },
    marginRight: {
        marginRight: '10px'
    }
});

export default function Home(): React.ReactElement {

    const classes = useStyles();
    const auth = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        setIsLoggedIn(auth.userData ? true : false);
    }, [auth.isLoading, auth.userData]);

    return (
        <>
            <h1>BrewApp</h1>
            {isLoggedIn ?

                <Paper className={classes.flex} elevation={0}>
                    <CardButton mainTitle="Neues Rezept" subTitle="Ein neues Rezept erstellen." targetRoute="/rezept" />
                    <CardButton mainTitle="Meine Rezepte" subTitle="Bereits erfasste Rezepte einsehen." targetRoute="/meinerezepte" />
                    <CardButton mainTitle="Meine Brauvorgänge" subTitle="Übersicht über die gebrauten Biere." targetRoute="/meinebrauvorgaenge" />
                    {/* <CardButton mainTitle="Brauvorgang starten" subTitle="Einen neuen Brauvorgang starten." /> */}
                    {/* <CardButton mainTitle="Auswertungen" subTitle="Statistiken über die gebrauten Biere einsehen." targetRoute="/auswertungen" /> */}
                    <CardButton mainTitle="Berechnungen" subTitle="Hilfreiche Berechnungen für den Brauereialltag." targetRoute="/berechnungen" />
                </Paper> :

                <>
                    <Typography>
                        Um die Applikation verwenden zu können ist ein Konto erforderlich.
                    </Typography>
                    <Box className={classes.buttonBox}>

                        <Button 
                            variant="outlined" 
                            color="primary" 
                            size="large" 
                            onClick={() => { window.location.href = '/Identity/Account/Register'; }} 
                            className={classes.marginRight}
                        >
                            Neues Konto erstellen
                        </Button>

                        <Button 
                            variant="outlined" 
                            color="primary" 
                            size="large" 
                            onClick={() => { auth.userManager.signinRedirect(); }}
                        >
                            Bestehendes Konto verwenden
                        </Button>

                    </Box>
                </>
            }

            <img src="/hopfen.jpg" alt="BrewApp Hopfen" className={classes.imgResponsive} />
        </>
    );
}