import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { RastModel } from "../../models/BrewAppModels";

interface RastListProps extends InputHTMLAttributes<HTMLInputElement> {
    rast?: RastModel[];
    onRastSelected: (rast: RastModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function RastList(props: RastListProps): React.ReactElement {

    const handleRowClick = (rastId: number | undefined) => {

        if (rastId && props && props.rast) {
            const rast = props.rast.find(item => item.id === rastId);
            if (rast) props.onRastSelected(rast);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Rasttabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Temperatur</TableCell>
                        <TableCell>Dauer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rast?.map((row) => (
                        <TableRow key={row.id ? row.id : Math.random()} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.bezeichnung}</TableCell>
                            <TableCell>{row.temperatur} °C</TableCell>
                            <TableCell>{row.dauer} Min.</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}