import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useParams } from 'react-router';
import { DauerProBrauphaseModel } from '../../models/BrewAppModels';
import { getData } from '../Common/AxiosWrapper';
import MessageProvider from '../Common/MessageProvider';

interface RouteParameter {
    brauvorgangId: string;
}

export default function Auswertungen(): React.ReactElement {

    const { brauvorgangId } = useParams<RouteParameter>();

    // https://github.com/reactchartjs/react-chartjs-2

    const getBaseData = (): ChartData => {
        return {
            //labels: ['Maischen', 'Laeutern', 'Kochen', 'Gaerung', 'Abfuellen'],
            labels: [] as string[],
            //labels: [],
            datasets: [
                {
                    label: 'Dauer',
                    //data: [12, 19, 3, 5, 2],
                    //data: [862580, 35, 2901, 0, 0],
                    //data: [] as DauerProBrauphaseModel[],
                    data: [] as number[],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    };

    const options/*: ChartOptions*/ = {
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    title: function (tooltipItem: any, data: any) {
                        //console.log("data", tooltipItem);
                        //return data['labels'][tooltipItem[0]['index']];
                        //return "Hanswale";
                        const dauerFormatted = currentData?.find(item => item.brauphase == tooltipItem[0].label);
                        return tooltipItem[0].label + ": " + dauerFormatted?.dauerFormatted;
                        // tooltipItem[0].dataIndex
                        // tooltipItem[0].dataset.data[tooltipItem[0].dataIndex].dauerFormatted
                    },
                    // label: function (tooltipItem: any, data: any) {
                    //     //return data['datasets'][0]['data'][tooltipItem['index']];
                    //     return "Hans";
                    // },
                    // afterLabel: function (tooltipItem, data) {
                    //     var dataset = data['datasets'][0];
                    //     var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                    //     return '(' + percent + '%)';
                    // }
                },
                //displayColors: false
            }
        },
    };

    const [chartData, setChartData] = useState<ChartData | undefined>(undefined);
    const [currentData, setCurrentData] = useState<DauerProBrauphaseModel[] | undefined>(undefined);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const parseChartData = (data: DauerProBrauphaseModel[]) => {
        const labels: string[] = [];
        const dataPoints: number[] = [];

        data.forEach(item => {
            labels.push(item.brauphase);
            dataPoints.push(Math.round(item.dauerInSekunden));
            //item.dauerInSekunden = Math.round(item.dauerInSekunden);
            //dataPoints.push(item);
        });

        const newChartData = getBaseData();
        newChartData.labels = labels;
        newChartData.datasets[0].data = dataPoints;

        console.log(newChartData);

        setCurrentData(data);
        setChartData(newChartData);
    };

    useEffect(() => {

        async function loadData() {
            setLoading(true);
            //const result = await getData<DauerProBrauphaseModel[]>(`/api/Brauvorgang/DauerProBrauphase/17`);  //13,14,17
            const result = await getData<DauerProBrauphaseModel[]>(`/api/Brauvorgang/DauerProBrauphase/${brauvorgangId}`);
            result.data ? parseChartData(result.data) : setCurrentError(result.errorMessage);
            setLoading(false);
        }

        if (brauvorgangId) {
            loadData();
        }

    }, [brauvorgangId]);



    return (
        <>
            <h1>Auswertungen {loading && <CircularProgress size={24} />}</h1>
            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
            <Alert severity="info">Durch das Anklicken einer Brauphase in der Legende kann diese ein- oder ausgeblendet werden.</Alert>
            <div style={{ position: 'relative', margin: 'auto', width: '55vw', height: '55vh' }}>
                <Pie data={chartData} options={options} />
            </div>
        </>
    );
}
