import { Box, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, useState, InputHTMLAttributes } from "react";
import { MalzModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import MalzList from "./MalzList";
import SearchButton from "../Common/SearchButton";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";

interface MalzSearchDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    onMalzSelected: (malz: MalzModel) => void;
}

export default function MalzSearchDialog(props: MalzSearchDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [suchbegriff, setSuchbegriff] = useState('');
    const [malzliste, setMalzliste] = useState<MalzModel[] | undefined>();
    const [loading, setLoading] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);

    const handleChangeSuchbegriff = (event: ChangeEvent<HTMLInputElement>) => {
        setSuchbegriff(event.target.value);
    };

    async function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setLoading(true);
        const result = await getData<MalzModel[]>(`/api/Malz/${suchbegriff}`);
        result.data ? setMalzliste(result.data) : setCurrentError(result.errorMessage);
        setLoading(false);
    }

    const handleMalzSelected = (malz: MalzModel) => {
        props.onMalzSelected(malz);
        handleOpenClose(false);
    };

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            setSuchbegriff('');
            setMalzliste(undefined);
        }
    };

    return (
        <GenericFullDialog
            showButton={true}
            buttonTitle="Malz suchen"
            title="Malz suchen"
            isOpen={open}
            onOpenClose={handleOpenClose}
            bottomComponent={(malzliste && malzliste.length > 0) ? <MalzList malz={malzliste} onMalzSelected={handleMalzSelected} /> : <Typography>Keine Daten gefunden.</Typography>}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
                <TextField id="outlined-basic" label="Suchbegriff" variant="outlined" onChange={handleChangeSuchbegriff} size="small" autoFocus />
                <SearchButton loading={loading} enableString={suchbegriff} />
            </Box>}
        />

    );
}