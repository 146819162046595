import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup } from "@material-ui/core";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BrauvorgangModel, NewBrauvorgangModel } from "../../models/BrewAppModels";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";
import RestrictedTextField from "../Common/RestrictedTextField";

interface BrauvorgangDialogProps {
    onOpenClose: (open: boolean) => void;
    isOpen: boolean;
    rezeptId: number;
}

export default function BrauvorgangDialog(props: BrauvorgangDialogProps): React.ReactElement {

    const [newBrauvorgang, setNewBrauvorgang] = useState<NewBrauvorgangModel>({} as NewBrauvorgangModel);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);

    useEffect(() => {
        
        async function getNextSudnummer() {
            const nextSudnummer = await getData<number>("/api/Brauvorgang/NextSudNummer");
            nextSudnummer.data ? setNewBrauvorgang({ ...newBrauvorgang, sudNummer: nextSudnummer.data }) : setCurrentError(nextSudnummer.errorMessage);
        }

        if (props.isOpen) {
            getNextSudnummer();
        }

    }, [props.isOpen]);

    const handleClose = () => {
        props.onOpenClose(false);
    };

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewBrauvorgang({ ...newBrauvorgang, [event.target.id]: event.target.value });
    }

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewBrauvorgang({ ...newBrauvorgang, [event.target.id]: event.target.checked });
    }

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);

        newBrauvorgang.rezeptId = props.rezeptId;
        newBrauvorgang.braudatum = new Date();

        try {
            const result = await axios.post<BrauvorgangModel>("/api/Brauvorgang", newBrauvorgang);
            history.push(`/brauvorgang/${result.data.id}`);
        }
        catch {
            setCurrentError("Die Brauschritte konnten nicht erstellt werden.");
        }
        finally {
            setLoading(false);
        }
        
    }

    return (
        <div>
            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={submitForm}>
                    <DialogTitle id="form-dialog-title">Rezept brauen</DialogTitle>
                    <DialogContent>

                        <DialogContentText>
                            Mit dieser Funktion kann ein Rezept gebraut werden.
                        </DialogContentText>

                        <RestrictedTextField
                            id="sudNummer"
                            label="Sudnummer"
                            variant="outlined"
                            value={newBrauvorgang.sudNummer || ''}
                            onChange={handleFieldChange}
                            size="small"
                            allowOnlyPositiveNumbers={true}
                            required
                            autoComplete="false"
                            fullWidth
                        />

                        <RestrictedTextField
                            id="biername"
                            label="Biername"
                            variant="outlined"
                            value={newBrauvorgang.biername || ''}
                            onChange={handleFieldChange}
                            size="small"
                            required
                            autoComplete="false"
                            autoFocus
                            fullWidth
                        />

                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newBrauvorgang.addHeizen || false}
                                        onChange={handleCheckboxChange}
                                        name="addHeizen"
                                        color="primary"
                                        id="addHeizen"
                                    />
                                }
                                label="Heizen beim Maischen"
                            />
                        </FormGroup>
                        
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newBrauvorgang.addUmfuellen || false}
                                        onChange={handleCheckboxChange}
                                        name="addUmfuellen"
                                        color="primary"
                                        id="addUmfuellen"
                                    />
                                }
                                label="Umfüllen nach Kochen"
                            />
                        </FormGroup>
                        
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>
                            Abbrechen
                        </Button>
                        <Button color="primary" type="submit" disabled={loading}>
                            Start {loading && <CircularProgress size={24} />}
                        </Button>
                    </DialogActions>

                </form>
            </Dialog>
        </div>
    );
}