import axios from "axios";
import { useState } from "react";
import { HefeModel, HerstellerModel, HopfenModel, MalzModel } from "../../models/BrewAppModels";
import HefeList from "../Hefe/HefeList";
import HerstellerSelect from "../Common/SelectBox/HerstellerSelect";
import HopfenList from "../Hopfen/HopfenList";
import HopfenSearchDialog from "../Hopfen/HopfenSearchDialog";
import MalzList from "../Malz/MalzList";
import { useStopwatch, useTime } from "react-timer-hook";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";

export default function Playground(): React.ReactElement {

    const [result, setResult] = useState("none");
    const [selectedHersteller, setSelectedHersteller] = useState<HerstellerModel | undefined>();
    const [hopfenliste, setHopfenliste] = useState<HopfenModel[] | undefined>();
    const [malzliste, setMalzliste] = useState<MalzModel[] | undefined>();
    const [hefeliste, setHefeliste] = useState<HefeModel[] | undefined>();

    // const {
    //     seconds,
    //     minutes,
    //     hours
    // } = useTime({});

    const stopwatchOffset = new Date(); 
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 300);

        const {
            seconds,
            minutes,
            hours,
            days,
            isRunning,
            start,
            pause,
            reset,
        } = useStopwatch({ autoStart: true, offsetTimestamp: stopwatchOffset.getTime() });

    const makeRequest = async () => {

        try {
            const callResult = await axios.get("/api/Hopfen/1");
            setResult(JSON.stringify(callResult.data));
        } catch (e) {
            setResult("Request failed: " + e);
        }

    };

    const makeSecureRequest = async () => {

        try {
            const callResult = await axios.get("/api/Hopfen");
            setResult(JSON.stringify(callResult.data));
        } catch (e) {
            setResult("Request failed: " + e);
        }

    };

    const makeSecureTypedRequest = async () => {

        try {
            const callResult = await axios.get<HopfenModel>("/api/Hopfen");
            setResult(JSON.stringify(callResult.data));
            console.log("Result", callResult.data);
        } catch (e) {
            setResult("Request failed: " + e);
        }

    };

    const onChangeHersteller = (hersteller?: HerstellerModel) => {
        setSelectedHersteller(hersteller);
    };

    const setSpecificHersteller = async (herstellerId: number | '') => {

        if (herstellerId === '') {
            setSelectedHersteller(undefined);
            return;
        }

        const result = await axios.get<HerstellerModel>("api/Hersteller/" + herstellerId);
        setSelectedHersteller(result.data);
    };

    const loadHopfen = async () => {
        const result = await axios.get<HopfenModel[]>("api/Hopfen");
        setHopfenliste(result.data);
    };

    const loadMalz = async () => {
        const result = await axios.get<MalzModel[]>("api/Malz");
        setMalzliste(result.data);
    };

    const loadHefe = async () => {
        const result = await axios.get<HefeModel[]>("api/Hefe");
        setHefeliste(result.data);
    };

    const malzSelected = (malz: MalzModel) => {
        console.log("Malz selected: ", malz)
    };

    const hopfenSelected = (hopfen: HopfenModel) => {
        console.log("Hopfen selected: ", hopfen)
    };

    const hefeSelected = (hefe: HefeModel) => {
        console.log("Hefe selected: ", hefe)
    };

    return (
        <>
            <h1>Playground</h1>
            <hr />
            <h3>Login/Error Tests</h3>
            <Button color="inherit" component={NavLink} exact to="/rezept">Rezept erfassen</Button>
            <Button color="inherit" component={NavLink} exact to="/meinerezepte">Meine Rezepte</Button>
            <Button color="inherit" component={NavLink} exact to="/rezept/1">Rezept 1</Button>
            <Button color="inherit" component={NavLink} exact to="/rezept/70000">Rezept 70000</Button>
            <Button color="inherit" component={NavLink} exact to="/meinebrauvorgaenge">Brauvorgänge</Button>
            <Button color="inherit" component={NavLink} exact to="/brauvorgang/1">Brauvorgang 1</Button>
            <Button color="inherit" component={NavLink} exact to="/brauvorgang/70000">Brauvorgang 70000</Button>
            <hr/>
            <button onClick={makeRequest}>Make Request</button>
            <button onClick={makeSecureRequest}>Make Secure Request</button>
            <button onClick={makeSecureTypedRequest}>Make Secure Typed Request</button>
            <pre>{result}</pre>
            <HerstellerSelect onHerstellerSelected={onChangeHersteller} selectedHersteller={selectedHersteller} />
            <pre>{selectedHersteller?.id} ({selectedHersteller?.name})</pre>
            <button onClick={(event) => setSpecificHersteller(3)}>Select Hersteller 3</button>
            <button onClick={(event) => setSpecificHersteller(4)}>Select Hersteller 4</button>
            <button onClick={(event) => setSpecificHersteller(5)}>Select Hersteller 5</button>
            <button onClick={(event) => setSpecificHersteller('')}>Select Hersteller None</button>
            <hr />
            <span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span>
            <hr />
            <button onClick={loadHopfen}>Load Hopfen</button>
            <HopfenList hopfen={hopfenliste} onHopfenSelected={hopfenSelected} />
            <HopfenSearchDialog onHopfenSelected={hopfenSelected} />
            <hr />
            <button onClick={loadMalz}>Load Malz</button>
            <MalzList malz={malzliste} onMalzSelected={malzSelected} />
            <hr />
            <button onClick={loadHefe}>Load Hefe</button>
            <HefeList hefe={hefeliste} onHefeSelected={hefeSelected} />
        </>
    );
}