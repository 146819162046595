import { Box, Button, TextField } from "@material-ui/core";
import { ChangeEvent, useEffect, useState, InputHTMLAttributes } from "react";
import { RastModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import RestrictedTextField from "../Common/RestrictedTextField";

interface RastDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    rast?: RastModel;
    onCompleted: (rast: RastModel) => void;
    reihenfolge?: number;
}

export default function RastDialog(props: RastDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [rast, setRast] = useState<RastModel>({} as RastModel);

    useEffect(() => {
        if (props.rast) {
            setOpen(true);
            setRast(props.rast);
        }
    }, [props.rast]);

    useEffect(() => {
        if (props.reihenfolge) {
            rast.reihenfolge = props.reihenfolge;
            setRast(rast);
        }
    }, [open, props.reihenfolge]);

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRast({ ...rast, [event.target.id]: event.target.value });
    }

    function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        props.onCompleted(rast);
        handleOpenClose(false);
    }

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            setRast({} as RastModel);
        }
    };

    return (
        <GenericFullDialog
            title="Rast hinzufügen"
            isOpen={open}
            onOpenClose={handleOpenClose}
            showButton={true}
            buttonTitle="Rast hinzufügen"
            topComponent={<Box component="form" onSubmit={submitForm}>
                
                <TextField
                    id="bezeichnung"
                    label="Bezeichnung"
                    variant="outlined"
                    onChange={handleFieldChange}
                    size="small"
                    required
                    autoComplete="false"
                    autoFocus
                />

                <RestrictedTextField
                    id="temperatur"
                    label="Temperatur"
                    variant="outlined"
                    value={rast.temperatur || ''}
                    onChange={handleFieldChange}
                    size="small"
                    unit="°C"
                    allowOnlyPositiveDecimals={true}
                    required
                    autoComplete="false"
                />

                <RestrictedTextField
                    id="dauer"
                    label="Dauer"
                    variant="outlined"
                    value={rast.dauer || ''}
                    onChange={handleFieldChange}
                    size="small"
                    unit="Min."
                    allowOnlyPositiveNumbers={true}
                    required
                    autoComplete="false"
                />

                <RestrictedTextField
                    id="reihenfolge"
                    label="Reihenfolge"
                    variant="outlined"
                    value={rast.reihenfolge || ''}
                    onChange={handleFieldChange}
                    size="small"
                    allowOnlyPositiveNumbers={true}
                    required
                    autoComplete="false"
                />

                <Button type="submit" variant="contained" color="primary" size="large">Übernehmen</Button>
            </Box>}
        />

    );

}