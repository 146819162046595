import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import axios from "axios";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { HerstellerModel } from "../../../models/BrewAppModels";

interface HerstellerProps extends InputHTMLAttributes<HTMLInputElement> {
    onHerstellerSelected: (hersteller?: HerstellerModel) => void;
    selectedHersteller?: HerstellerModel;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default function HerstellerSelect(props: HerstellerProps): React.ReactElement {

    const classes = useStyles();
    const [hersteller, setHersteller] = useState<HerstellerModel[]>([]);

    const handleChangeHersteller = (event: React.ChangeEvent<{ value: unknown }>) => {
        console.log("handleChangeHersteller", event.target.value);
        props.onHerstellerSelected(hersteller.find(item => item.id === event.target.value));
    };

    useEffect(() => {
        async function getHersteller() {
            const result = await axios.get<HerstellerModel[]>("api/Hersteller");
            setHersteller(result.data);
        }
        getHersteller();
    }, []);

    return <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="hersteller-select-label">Hersteller</InputLabel>
        <Select
            labelId="hersteller-select-label"
            id="hersteller-select"
            value={props.selectedHersteller?.id || ''}
            onChange={handleChangeHersteller}
            label="Hersteller"
        >
            {hersteller?.map(item => <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>) }
        </Select>
    </FormControl>;
}