import { CircularProgress } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { BrauvorgangOverviewModel } from "../../models/BrewAppModels";
import BrauvorgangList from "../Brauvorgang/BrauvorgangList";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";

export default function MeineBrauvorgaenge(): React.ReactElement {
    
    const [brauvorgaenge, setBrauvorgaenge] = useState<BrauvorgangOverviewModel[] | undefined>();
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        async function loadBrauvorgaenge() {

            setLoading(true);
            const result = await getData<BrauvorgangOverviewModel[]>("/api/Brauvorgang");
            result.data ? setBrauvorgaenge(result.data) : setCurrentError(result.errorMessage);
            setLoading(false);

        }

        if (auth.userData) {
            loadBrauvorgaenge();
        }
        else if (!auth.userData) {
            // Dieser Fall kann eintreten, wenn die Seite direkt durch die Eingabe
            // des URL aufgerufen wird, jedoch das SilentLogin noch nicht erfolgt ist.
            // Wir wissen jedoch noch nicht, ob das Login überhaupt erfolgreich sein wird.
            if (!auth.isLoading) setCurrentError("Es ist kein Benutzender angemeldet.");
        }

    }, [auth.userData, auth.isLoading]);

    const brauvorgangSelected = (brauvorgang: BrauvorgangOverviewModel) => {
        history.push(`/brauvorgang/${brauvorgang.id}`);
    };

    return (
        <>
            <h1>Meine Brauvorgänge</h1>
            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
            {loading ? <CircularProgress size={24} /> : <BrauvorgangList onBrauvorgangSelected={brauvorgangSelected} brauvorgaenge={brauvorgaenge} />}
        </>
    );
}