import { makeStyles, Theme, createStyles, List, CircularProgress, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useStopwatch, useTimer } from "react-timer-hook";
import { BrauschrittModel, BrauvorgangModel } from "../../models/BrewAppModels";
import BrauschrittDialog from "../Brauvorgang/BrauschrittDialog";
import BrauschrittExpiredDialog from "../Brauvorgang/BrauschrittExpiredDialog";
import BrauschrittListItem from "../Brauvorgang/BrauschrittListItem";
import { getData } from "../Common/AxiosWrapper";
import DisplayTime from "../Common/DisplayTime";
import MessageProvider from "../Common/MessageProvider";
import TimelineIcon from '@material-ui/icons/Timeline';

interface RouteParameter {
    brauvorgangId: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);


export default function Brauvorgang(): React.ReactElement {

    const auth = useAuth();

    const { brauvorgangId } = useParams<RouteParameter>();
    const classes = useStyles();
    const history = useHistory();

    const [brauvorgang, setBrauvorgang] = useState<BrauvorgangModel>({} as BrauvorgangModel);
    const [brauschrittDialogOpen, setBrauschrittDialogOpen] = useState(false);
    const [brauschrittExpiredDialogOpen, setBrauschrittExpiredDialogOpen] = useState(false);
    const [editBrauschritt, setEditBrauschritt] = useState<BrauschrittModel | undefined>({} as BrauschrittModel);
    const [onlyOpenbrauschritte, setOnlyOpenbrauschritte] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [audio, setAudio] = useState(new Audio("/alarm.mp3"));
    const [currentBrauschritt, setCurrentBrauschritt] = useState<BrauschrittModel | undefined>();

    const stopwatch = useStopwatch({ autoStart: true });
    const countdown = useTimer({ expiryTimestamp: 1, autoStart: false, onExpire: () => brauschrittExpired() });

    useEffect(() => {

        async function loadBrauvorgang() {
            
            setLoading(true);
            const loadedBrauvorgang = await getData<BrauvorgangModel>(`/api/Brauvorgang/${brauvorgangId}`);
            
            if (loadedBrauvorgang.data) {
                setBrauvorgang(loadedBrauvorgang.data)

                const stopwatchOffset = new Date();
                // Convert Braudatum from UTC. Braudatum is anyways a string(!) at runtime.
                const parsedBraudatum = new Date(loadedBrauvorgang.data.braudatum + "Z");
                stopwatch.reset(stopwatchOffset.getTime() + (stopwatchOffset.getTime() - parsedBraudatum.getTime()));
            } else {
                setCurrentError(loadedBrauvorgang.errorMessage)
            }

            setLoading(false);
        }
        
        if (brauvorgangId && auth.userData) {
            loadBrauvorgang();
        }
        else if (brauvorgangId && !auth.userData) {
            // Dieser Fall kann eintreten, wenn die Seite direkt durch die Eingabe
            // des URL aufgerufen wird, jedoch das SilentLogin noch nicht erfolgt ist.
            // Wir wissen jedoch noch nicht, ob das Login überhaupt erfolgreich sein wird.
            if (!auth.isLoading) setCurrentError("Es ist kein Benutzender angemeldet.");
        }
        else if (!brauvorgangId) {
            setBrauvorgang({} as BrauvorgangModel);
        }

    }, [brauvorgangId, auth.userData, auth.isLoading]);

    useEffect(() => {

        if (brauvorgang && onlyOpenbrauschritte) {
            const brauschritt = brauvorgang.brauschritte?.find(item => item.status === "Abgeschlossen" || item.status === "InBearbeitung");
            if (brauschritt) {
                setOnlyOpenbrauschritte(false);
            }
        }

        brauvorgang && setCurrentBrauschritt(brauvorgang.brauschritte?.find(item => item.status === "InBearbeitung"))

    }, [brauvorgang, onlyOpenbrauschritte]);

    useEffect(() => {
        // Request permission for Notification if not already granted
        if (Notification.permission === "default") {
            Notification.requestPermission();
        }
    }, []);

    const handleRowClick = (id: number) => {
        const brauvorgangEdit: BrauschrittModel | undefined = brauvorgang.brauschritte?.find(item => item.id === id);
        if (brauvorgangEdit?.status === "Offen" || brauvorgangEdit?.status === "InBearbeitung") {
            setEditBrauschritt(brauvorgangEdit);
            setBrauschrittDialogOpen(true);
        }
    };

    const handleOpenCloseBrauschrittDialog = async (isOpen: boolean, closeBrauschritt: boolean) => {
        
        if (closeBrauschritt && editBrauschritt?.status === "InBearbeitung") {
            const newBrauschritte: BrauschrittModel[] = [];
            let lastClosed = false;

            brauvorgang.brauschritte?.forEach(item => {
                if (item.id !== editBrauschritt!.id) {
                    if (lastClosed) {
                        newBrauschritte.push({ ...item, status: "InBearbeitung", startdatum: new Date() });
                        lastClosed = false;
                        if (item.vorgeseheneDauer) {
                            const time = new Date();
                            time.setSeconds(time.getSeconds() + item.vorgeseheneDauer * 60);
                            countdown.restart(time.getTime());
                        }
                    }
                    else {
                        newBrauschritte.push(item);
                    }
                }
                else {
                    newBrauschritte.push({ ...item, status: "Abgeschlossen", enddatum: new Date() });
                    lastClosed = true;
                    countdown.pause();
                }
            });

            const newBrauvorgang = { ...brauvorgang, brauschritte: newBrauschritte };
            await saveBrauvorgang(newBrauvorgang) && setBrauvorgang(newBrauvorgang);  // <-- this is async, so we need to use `newBrauvorgang` to ensure we have actual data
        }
        else if (closeBrauschritt && editBrauschritt?.status === "Offen") {
            const newBrauschritte: BrauschrittModel[] = [];

            brauvorgang.brauschritte?.forEach(item => {
                if (item.id === editBrauschritt!.id) {
                    newBrauschritte.push({ ...item, status: "InBearbeitung", startdatum: new Date() });
                }
                else {
                    newBrauschritte.push(item);
                }
            });

            const newBrauvorgang = { ...brauvorgang, brauschritte: newBrauschritte };
            await saveBrauvorgang(newBrauvorgang) && setBrauvorgang(newBrauvorgang);  // <-- this is async, so we need to use `newBrauvorgang` to ensure we have actual data
        }

        setBrauschrittDialogOpen(isOpen);
    };

    const handleOpenCloseBrauschrittExpiredDialog = (open: boolean) => {
        console.log("closing expired dialog");
        audio.pause();
        setBrauschrittExpiredDialogOpen(false);
    };

    const brauschrittExpired = async () => {
        console.log("brauschritt has expired");
        setBrauschrittExpiredDialogOpen(true);

        const newNotification = new Notification("BrewApp", {
            body: `${currentBrauschritt?.bezeichnung || "Der Brauschritt"} ist abgelaufen.`,
            requireInteraction: true,
            icon: "/favicon.ico",
            vibrate: [200, 100, 200, 100, 200, 100, 200, 100, 200]
        });

        try {
            audio.loop = true;
            await audio.play();
        }
        catch (err) {
            console.warn("Failed to play audio", err);
        }
        
    };

    const saveBrauvorgang = async (brauvorgang: BrauvorgangModel): Promise<boolean> => {
        setSaving(true);
        try {
            await axios.put(`/api/Brauvorgang/${brauvorgang.id}`, brauvorgang);
            return true;
        }
        catch {
            setCurrentError("Brauvorgang konnte nicht gespeichert werden.");
            return false;
        } finally {
            setSaving(false);
        }
    };

    const handleAuswertungClick = () => {
        history.push(`/auswertungen/${brauvorgang.id}`);
    };

    return (
        <>
            <h1>Brauvorgang: {brauvorgang.biername}</h1>
            
            <Button variant="contained" startIcon={<TimelineIcon />} onClick={handleAuswertungClick}>
                Auswertung
            </Button>

            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
            {loading ? <CircularProgress size={24} /> : 
            <>
                <p>Bisherige Dauer: <DisplayTime time={stopwatch} displayDay={true} />{countdown.isRunning && <> | Restdauer aktueller Schritt: <DisplayTime time={countdown} /></>}</p>

                { onlyOpenbrauschritte && <Alert severity="info">Brauschritt anklicken, um diesen zu starten.</Alert> }

                <List className={classes.root} component="nav">
                    {brauvorgang.brauschritte?.map((item, index, array) => 
                        <BrauschrittListItem key={item.id} 
                                            brauschritt={item} 
                                            showSubheader={index === 0 || array[index - 1].gruppe !== item.gruppe} 
                                            onClick={handleRowClick} 
                                            remainingTime={countdown} />
                        )}
                </List>

                <BrauschrittDialog isOpen={brauschrittDialogOpen} 
                                onOpenClose={handleOpenCloseBrauschrittDialog} 
                                brauschritt={editBrauschritt!}
                                isLoading={saving} />

                <BrauschrittExpiredDialog isOpen={brauschrittExpiredDialogOpen} 
                                        onOpenClose={handleOpenCloseBrauschrittExpiredDialog}
                                        brauschrittName={currentBrauschritt?.bezeichnung} />
            </>
            }
        </>
    );
}