import { AppBar, Card, CardContent, createStyles, Dialog, IconButton, makeStyles, Slide, Theme, Toolbar, Typography } from "@material-ui/core";
import { forwardRef, ReactNode } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface GenericFullDialogProps {
    topComponent: ReactNode;
    bottomComponent?: ReactNode;
    title: string;
    showButton?: boolean;
    buttonTitle?: string;
    onOpenClose: (open: boolean) => void;
    isOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        nopadding: {
            padding: 'none'
        }
    }),
);

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenericFullDialog(props: GenericFullDialogProps): React.ReactElement {
    const classes = useStyles();

    const handleClickOpen = () => {
        props.onOpenClose(true);
    };

    const handleClose = () => {
        props.onOpenClose(false);
    };

    return (
        <>
            {props.showButton &&
            <IconButton color="primary" onClick={handleClickOpen} className={classes.nopadding}>
                <AddCircleIcon fontSize="large" aria-label={props.buttonTitle} />
            </IconButton>
            }
            <Dialog fullScreen open={props.isOpen} onClose={handleClose} TransitionComponent={Transition}>

                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Card>
                    <CardContent>
                        {props.topComponent}
                    </CardContent>
                </Card>

                {props.bottomComponent &&
                <Card>
                    <CardContent>
                        {props.bottomComponent}
                    </CardContent>
                </Card>
                }

            </Dialog>
        </>
    );
}