import { createStyles, makeStyles, Theme, TextField } from "@material-ui/core";
import { InputHTMLAttributes, ReactNode } from "react";

interface GenericStringSelectProps extends InputHTMLAttributes<HTMLInputElement> {
    value?: string;
    onValueSelected: (value: string) => void;
    title: string;
    children: ReactNode;
    isRequired?: boolean;
    minWidth?: number;
    small?: boolean;
}

export default function GenericStringSelect(props: GenericStringSelectProps): React.ReactElement {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
                minWidth: props.minWidth ? props.minWidth : 140,
            },
        }),
    );

    const classes = useStyles();

    const handleChangeValue = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.onValueSelected(event.target.value as string);
    };

    return (
            <TextField
                select
                variant="outlined"
                className={classes.formControl}
                size={props.small ? "small" : undefined}
                value={props.value || ''}
                onChange={handleChangeValue}
                label={props.title}
                required={props.isRequired}
            >
                {props.children}
            </TextField>
    );
}