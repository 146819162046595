import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { HefeModel } from "../../models/BrewAppModels";

interface HefeListProps extends InputHTMLAttributes<HTMLInputElement> {
    hefe?: HefeModel[];
    onHefeSelected: (hefe: HefeModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function HefeList(props: HefeListProps): React.ReactElement {

    const handleRowClick = (hefeId: number | undefined) => {

        if (hefeId && props && props.hefe) {
            const hefe = props.hefe.find(item => item.id === hefeId);
            if (hefe) props.onHefeSelected(hefe);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Hefetabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Temperaturtoleranz (°C)</TableCell>
                        <TableCell>Alkoholtoleranz</TableCell>
                        <TableCell>Art</TableCell>
                        <TableCell>Vergärung</TableCell>
                        <TableCell>Hersteller</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.hefe?.map((row) => (
                        <TableRow key={row.id} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.bezeichnung}</TableCell>
                            <TableCell>{row.minimaleTemperatur} - {row.maximaleTemperatur}</TableCell>
                            <TableCell>{row.alkoholtoleranzVon} - {row.alkoholtoleranzBis}</TableCell>
                            <TableCell>{row.art}</TableCell>
                            <TableCell>{row.vergaerung}</TableCell>
                            <TableCell>{row.hersteller?.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}