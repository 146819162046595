import axios from "axios";

interface GetDataResult<Type> {
    data?: Type;
    errorMessage?: string;
    error?: RequestError;
}

class RequestError {
    constructor(
        public statusCode: string, 
        public statusText: string,
        public requestUrl: string) {}
}

export async function getData<Type>(url: string): Promise<GetDataResult<Type>> {
    try {
        const result = await axios.get<Type>(url).catch(err => {
            console.log(`Request to ${err.config.url} failed with status code ${err.response.status} (${err.response.statusText})`, err);
            throw new RequestError(err.response.status, err.response.statusText, err.config.url);
        });
        return { data: result.data };
    }
    catch (error) {
        const e = error as RequestError;

        if (e.statusCode === "401") {
            return { errorMessage: "Es ist kein Benutzender angemeldet (401).", error: e };
        }
        else if (e.statusCode === "404") {
            return { errorMessage: "Zu dieser Anfrage wurden keine Daten gefunden (404).", error: e };
        }
        else {
            return { errorMessage: `Daten konnten nicht geladen werden (${e.statusText}).`, error: e };
        }
    }
}