import { Box, Button } from "@material-ui/core";
import { ChangeEvent, useEffect, useState, InputHTMLAttributes } from "react";
import { Beigabezeitpunkt, HopfenbeigabeModel, HopfenModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import RestrictedTextField from "../Common/RestrictedTextField";
import BeigabezeitpunktSelect from "../Common/SelectBox/BeigabezeitpunktSelect";

interface HopfenbeigabeDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    hopfen?: HopfenModel;
    onCompleted: (hopfenbeigabe: HopfenbeigabeModel) => void;
}

export default function HopfenbeigabeDialog(props: HopfenbeigabeDialogProps): React.ReactElement {
    
    const [open, setOpen] = useState(false);
    const [hopfenbeigabe, setHopfenbeigabe] = useState<HopfenbeigabeModel>({} as HopfenbeigabeModel);

    useEffect(() => {
        if (props.hopfen) {
            setOpen(true);
            setHopfenbeigabe({ hopfen: props.hopfen } as HopfenbeigabeModel);
        }
    }, [props.hopfen]);

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHopfenbeigabe({ ...hopfenbeigabe, [event.target.id]: event.target.value });
    }

    const handleBeigabezeitpunktSelected = (value: string) => {
        setHopfenbeigabe({ ...hopfenbeigabe, beigabezeitpunkt: value as Beigabezeitpunkt });
    };

    function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        props.onCompleted(hopfenbeigabe);
        handleOpenClose(false);
    }

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
    };

    return (
        <GenericFullDialog
            title={`Hopfenbeigabe ${props.hopfen?.bezeichnung}`}
            isOpen={open}
            onOpenClose={handleOpenClose}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <RestrictedTextField
                    id="menge" 
                    label="Menge" 
                    variant="outlined" 
                    value={hopfenbeigabe.menge || ''}
                    onChange={handleFieldChange} 
                    size="small" 
                    unit="g"
                    allowOnlyPositiveDecimals={true}
                    autoComplete="false"
                    required
                    autoFocus
                />
                <RestrictedTextField
                    id="kochdauer" 
                    label="Kochdauer" 
                    variant="outlined" 
                    value={hopfenbeigabe.kochdauer || ''}
                    onChange={handleFieldChange} 
                    size="small"
                    unit="Min."
                    allowOnlyPositiveNumbers={true}
                    autoComplete="false"
                />
                <BeigabezeitpunktSelect 
                    onValueSelected={handleBeigabezeitpunktSelected}
                    value={hopfenbeigabe.beigabezeitpunkt}
                    isRequired={true}
                    minWidth={200}
                    small={true} 
                />
                <Button type="submit" variant="contained" color="primary" size="large">Übernehmen</Button>
            </Box>}
            />

    );

}