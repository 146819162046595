import { Button, CircularProgress, createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";
import RestrictedTextField from "../Common/RestrictedTextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root, & .MuiButton-root': {
                margin: theme.spacing(2),
            },
        },
        calcbutton: {
            width: '27ch',
        }
    }),
);

export default function Berechnungen(): React.ReactElement {

    const classes = useStyles();
    
    const [stammwuerze, setStammwuerze] = useState('');
    const [endvergaerungsgrad, setEndvergaerungsgrad] = useState('');
    const [alkoholgehalt, setAlkoholgehalt] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [hasInputError, setHasInputError] = useState(false);

    const handleCalculateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setLoading(true);

        const result = await getData<number>(`/api/Berechnung/AlkoholgehaltVolumenprozente?stammwuerze=${stammwuerze}&endvergaerungsgrad=${endvergaerungsgrad}`);

        result.data ? setAlkoholgehalt(result.data) : setCurrentError(result.errorMessage);

        setLoading(false);
    };

    useEffect(() => {
        const stw = Number(stammwuerze);
        const evg = Number(endvergaerungsgrad);
        
        if ((!isNaN(stw) && !isNaN(evg)) && stw < evg) {
            setHasInputError(true);
        }
        else {
            setHasInputError(false);
        }

    }, [stammwuerze, endvergaerungsgrad]);

    return (
        <>
            <h1>Berechnungen</h1>

            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />

            <h2>Alkoholgehalt</h2>

            <form autoComplete="off" onSubmit={handleCalculateSubmit} className={classes.root}>
                <div>
                    <Tooltip arrow title="Die Stammwürze hat die Masseinheit Grad Plato (°P), ist eine zentrale Messgrösse beim Bierbrauen und definiert den Dichtegehalt der Flüssigkeit vor der Vergärung. Ihr Hauptzweck dient dem späteren Bestimmen des Alkoholgehalts und Nährwert des fertigen Bieres. Der typische Wertbereich liegt bei 8-21 °P.">
                        <RestrictedTextField
                            id="stammwuerze"
                            label="Stammwürze"
                            placeholder="15.8"
                            required
                            variant="outlined"
                            unit="°P"
                            allowOnlyPositiveDecimals={true}
                            value={stammwuerze}
                            onChange={(e) => setStammwuerze(e.target.value as string)} />
                    </Tooltip>
                </div>
                <div>
                    <Tooltip arrow title="Der Endvergärungsgrad ist die Dichte der Flüssigkeit nach abgeschlossener Gärung. Der typische Wertbereich liegt bei 2-6 °P.">
                        <RestrictedTextField
                            id="endvergaerungsgrad"
                            label="Endvergärungsgrad"
                            placeholder="3.5"
                            required
                            variant="outlined"
                            unit="°P"
                            allowOnlyPositiveDecimals={true}
                            value={endvergaerungsgrad}
                            error={hasInputError}
                            helperText={hasInputError && 'Endvergärungsgrad darf nicht grösser als Stammwürze sein.'}
                            onChange={(e) => setEndvergaerungsgrad(e.target.value as string)} />
                    </Tooltip>
                </div>
                <div>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        disabled={loading || hasInputError} 
                        type="submit" 
                        className={classes.calcbutton}
                    >
                        Berechnen {loading && <CircularProgress size={24} />}
                    </Button>
                </div>
                <div>
                    <RestrictedTextField
                        id="alkoholgehalt"
                        label="Alkoholgehalt"
                        disabled
                        variant="outlined"
                        unit="Vol. %"
                        allowOnlyPositiveDecimals={true}
                        value={alkoholgehalt}
                        onChange={() => undefined}
                    />
                </div>

            </form>

        </>
    );
}