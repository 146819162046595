import { Box, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, useState, InputHTMLAttributes } from "react";
import { HopfenModel } from "../../models/BrewAppModels";
import HopfenList from "./HopfenList";
import GenericFullDialog from "../Common/GenericFullDialog";
import SearchButton from "../Common/SearchButton";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";

interface HopfenSearchDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    onHopfenSelected: (hopfen: HopfenModel) => void;
}

export default function HopfenSearchDialog(props: HopfenSearchDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [suchbegriff, setSuchbegriff] = useState('');
    const [hopfenliste, setHopfenliste] = useState<HopfenModel[] | undefined>();
    const [loading, setLoading] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);

    const handleChangeSuchbegriff = (event: ChangeEvent<HTMLInputElement>) => {
        setSuchbegriff(event.target.value);
    };

    async function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setLoading(true);
        const result = await getData<HopfenModel[]>(`/api/Hopfen/${suchbegriff}`);
        result.data ? setHopfenliste(result.data) : setCurrentError(result.errorMessage);
        setLoading(false);
    }

    const handleHopfenSelected = (hopfen: HopfenModel) => {
        console.log("Hopfen selected: ", hopfen)
        props.onHopfenSelected(hopfen);
        handleOpenClose(false);
    };

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            setSuchbegriff('');
            setHopfenliste(undefined);
        }
    };

    return (
        <GenericFullDialog 
            showButton={true} 
            buttonTitle="Hopfen suchen" 
            title="Hopfen suchen" 
            isOpen={open}
            onOpenClose={handleOpenClose}
            bottomComponent={(hopfenliste && hopfenliste.length > 0) ? <HopfenList hopfen={hopfenliste} onHopfenSelected={handleHopfenSelected} /> : <Typography>Keine Daten gefunden.</Typography>}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
                <TextField id="outlined-basic" label="Suchbegriff" variant="outlined" onChange={handleChangeSuchbegriff} size="small" autoFocus />
                <SearchButton loading={loading} enableString={suchbegriff} />
            </Box>}
        />

    );
}