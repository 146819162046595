import { MenuItem } from "@material-ui/core";
import { SelectProps } from "../../../interfaces/SelectProps";
import GenericStringSelect from "./GenericStringSelect";

export default function VergaerungSelect(props: SelectProps): React.ReactElement {

    return (
        <GenericStringSelect title="Vergärung"  {...props}>
            <MenuItem value="Obergaerig" key="Obergaerig">Obergärig</MenuItem>
            <MenuItem value="Untergaerig" key="Untergaerig">Untergärig</MenuItem>
        </GenericStringSelect>
    );

}