import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { HopfenbeigabeModel } from "../../models/BrewAppModels";
import DeleteIcon from '@material-ui/icons/Delete';

interface HopfenbeigabeListProps extends InputHTMLAttributes<HTMLInputElement> {
    hopfen?: HopfenbeigabeModel[];
    onHopfenSelected: (hopfen: HopfenbeigabeModel) => void;
    onHopfenDeleted: (hopfen: HopfenbeigabeModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function HopfenbeigabeList(props: HopfenbeigabeListProps): React.ReactElement {

    const handleRowClick = (hopfenId: number | undefined) => {

        if (hopfenId && props && props.hopfen) {
            const hopfen = props.hopfen.find(item => item.id === hopfenId);
            if (hopfen) props.onHopfenSelected(hopfen);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Hopfentabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Menge</TableCell>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Kochdauer</TableCell>
                        <TableCell>Beigabe</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.hopfen?.map((row) => (
                        <TableRow key={row.id ? row.id : Math.random()} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.menge}g</TableCell>
                            <TableCell>{row.hopfen.bezeichnung}</TableCell>
                            <TableCell>{row.kochdauer && `${row.kochdauer} Min.`}</TableCell>
                            <TableCell>{row.beigabezeitpunkt}</TableCell>
                            <TableCell align="right"><IconButton aria-label="Löschen" size="small" onClick={() => props.onHopfenDeleted(row)}><DeleteIcon /></IconButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}