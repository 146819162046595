import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { MalzModel } from "../../models/BrewAppModels";

interface MalzListProps extends InputHTMLAttributes<HTMLInputElement> {
    malz?: MalzModel[];
    onMalzSelected: (malz: MalzModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function MalzList(props: MalzListProps): React.ReactElement {

    const handleRowClick = (malzId: number | undefined) => {

        if (malzId && props && props.malz) {
            const malz = props.malz.find(item => item.id === malzId);
            if (malz) props.onMalzSelected(malz);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Malztabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Farbe (EBC)</TableCell>
                        <TableCell>Max. Zugabe (%)</TableCell>
                        <TableCell>Hersteller</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.malz?.map((row) => (
                        <TableRow key={row.id} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.bezeichnung}</TableCell>
                            <TableCell>{row.farbeVon} - {row.farbeBis}</TableCell>
                            <TableCell>{row.zugabeVon} - {row.zugabeBis}</TableCell>
                            <TableCell>{row.hersteller?.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}