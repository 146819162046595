import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { BrauvorgangOverviewModel } from "../../models/BrewAppModels";

interface BrauvorgangListProps extends InputHTMLAttributes<HTMLInputElement> {
    brauvorgaenge?: BrauvorgangOverviewModel[];
    onBrauvorgangSelected: (brauvorgang: BrauvorgangOverviewModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function BrauvorgangList(props: BrauvorgangListProps): React.ReactElement {

    const handleRowClick = (brauvorgangId: number | undefined) => {

        if (brauvorgangId && props && props.brauvorgaenge) {
            const brauvorgang = props.brauvorgaenge.find(item => item.id === brauvorgangId);
            if (brauvorgang) props.onBrauvorgangSelected(brauvorgang);
        }

    };

    const classes = useStyles();

    return (

        <TableContainer component={Paper}>
            <Table aria-label="Brauvorgangtabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Biername</TableCell>
                        <TableCell>Braudatum</TableCell>
                        <TableCell>Abfülldatum</TableCell>
                        <TableCell>Stw.</TableCell>
                        <TableCell>EV</TableCell>
                        <TableCell>Menge</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.brauvorgaenge?.map((row) => (
                        <TableRow key={row.id} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.sudNummer}</TableCell>
                            <TableCell>{row.biername}</TableCell>
                            <TableCell>{new Date(row.braudatum + "Z").toLocaleString("de-CH")}</TableCell>
                            <TableCell>{row.abfuelldatum}</TableCell>
                            <TableCell>{row.stammwuerze}</TableCell>
                            <TableCell>{row.endvergaerungsgrad}</TableCell>
                            <TableCell>{row.endmenge}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}