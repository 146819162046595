import { MenuItem } from "@material-ui/core";
import { SelectProps } from "../../../interfaces/SelectProps";
import GenericStringSelect from "./GenericStringSelect";

export default function BeigabezeitpunktSelect(props: SelectProps): React.ReactElement {

    return (
        <GenericStringSelect title="Beigabezeitpunkt" {...props}>
            <MenuItem value="Vorderwuerze" key="Vorderwuerze">Vorderwürze</MenuItem>
            <MenuItem value="Kochen" key="Kochen">Kochen</MenuItem>
            <MenuItem value="Trocken" key="Trocken">Trocken</MenuItem>
        </GenericStringSelect>
    );

}