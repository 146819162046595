import { Box, Button, CircularProgress, createStyles, makeStyles, Paper, Tab, Tabs, TextField, Theme, Tooltip } from "@material-ui/core";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BierstilModel, BrauvorgangOverviewModel, HefebeigabeModel, HefeModel, HopfenbeigabeModel, HopfenModel, MalzbeigabeModel, MalzModel, RastModel, RezeptModel, VergaerungsArt } from "../../models/BrewAppModels";
import BierstilSelect from "../Common/SelectBox/BierstilSelect";
import VergaerungSelect from "../Common/SelectBox/VergaerungSelect";
import HefebeigabeDialog from "../Hefe/HefebeigabeDialog";
import HefebeigabeList from "../Hefe/HefebeigabeList";
import HefeSearchDialog from "../Hefe/HefeSearchDialog";
import HopfenbeigabeDialog from "../Hopfen/HopfenbeigabeDialog";
import HopfenbeigabeList from "../Hopfen/HopfenbeigabeList";
import HopfenSearchDialog from "../Hopfen/HopfenSearchDialog";
import MalzbeigabeDialog from "../Malz/MalzbeigabeDialog";
import MalzbeigabeList from "../Malz/MalzbeigabeList";
import RastList from "../Rast/RastList";
import MalzSearchDialog from "../Malz/MalzSearchDialog";
import RastDialog from "../Rast/RastDialog";
import { useAuth } from "oidc-react";
import RestrictedTextField from "../Common/RestrictedTextField";
import BrauvorgangDialog from "../Brauvorgang/BrauvorgangDialog";
import MessageProvider from "../Common/MessageProvider";
import { getData } from "../Common/AxiosWrapper";
import TabPanel from "../Common/TabPanel";
import BrauvorgangList from "../Brauvorgang/BrauvorgangList";
import { Alert } from "@material-ui/lab";

interface RouteParameter {
    rezeptId: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
        },
        containerPaper: {
            marginBottom: theme.spacing(4),
        },
        mr: {
            marginRight: theme.spacing(2)
        }
    })
);

export default function Rezept(): React.ReactElement {

    const classes = useStyles();
    const auth = useAuth();

    const { rezeptId } = useParams<RouteParameter>();
    const history = useHistory();

    const [rezept, setRezept] = useState<RezeptModel>({} as RezeptModel);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [currentSuccess, setCurrentSuccess] = useState<string | undefined>(undefined);
    const [selectedMalz, setSelectedMalz] = useState<MalzModel>();
    const [selectedHopfen, setSelectedHopfen] = useState<HopfenModel>();
    const [selectedHefe, setSelectedHefe] = useState<HefeModel>();
    const [isCreateBrauvorgangOpen, setIsCreateBrauvorgangOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [brauvorgaenge, setBrauvorgaenge] = useState<BrauvorgangOverviewModel[] | undefined>(undefined);
    const [loadingBrauvorgaenge, setLoadingBrauvorgaenge] = useState(false);

    async function loadRezept() {

        setLoading(true);
        const result = await getData<RezeptModel>(`/api/Rezept/${rezeptId || rezept.id}`);
        if (result.data) {
            result.data.rasten = result.data.rasten?.sort((a, b) => a.reihenfolge - b.reihenfolge);
            setRezept(result.data);
        }
        else {
            setCurrentError(result.errorMessage);
        }
        setLoading(false);
    }

    useEffect(() => {

        if (rezeptId && auth.userData) {
             loadRezept();
        }
        else if (rezeptId && !auth.userData) {
            // Dieser Fall kann eintreten, wenn die Seite direkt durch die Eingabe
            // des URL aufgerufen wird, jedoch das SilentLogin noch nicht erfolgt ist.
            // Wir wissen jedoch noch nicht, ob das Login überhaupt erfolgreich sein wird.
            if (!auth.isLoading) setCurrentError("Es ist kein Benutzender angemeldet.");
        }
        else if (!rezeptId) {
            // Neues Rezept
            setRezept({} as RezeptModel);
            setBrauvorgaenge(undefined);
            setCurrentTab(0);
        }

    }, [rezeptId, auth.userData, auth.isLoading]);

    const onChangeBierstil = (bierstil?: BierstilModel) => {
        if (bierstil) {
            setRezept({ ...rezept, bierstil: bierstil });
        }
    };
    
    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRezept({ ...rezept, [event.target.id]: event.target.value });
    }

    const handleChangeVergaerung = (value: string) => {
        setRezept({ ...rezept, vergaerung: value as VergaerungsArt });
    };

    // Malz
    const malzSelected = (malz: MalzModel) => {
        console.log("Malz selected: ", malz);
        setSelectedMalz(malz);
    };

    const malzBeigabeSelected = (malz: MalzbeigabeModel) => {
        console.log("Malz selected: ", malz);
    };

    const malzbeigabeCompleted = (malzbeigabe: MalzbeigabeModel) => {
        console.log("Malzbeigabe created", malzbeigabe);
        setRezept({ ...rezept, malzbeigaben: [malzbeigabe, ...rezept.malzbeigaben || []] });
        setSelectedMalz(undefined);
    };

    // Hopfen
    const hopfenSelected = (hopfen: HopfenModel) => {
        console.log("Hopfen selected: ", hopfen);
        setSelectedHopfen(hopfen);
    };

    const hopfenBeigabeSelected = (hopfen: HopfenbeigabeModel) => {
        console.log("Hopfen selected: ", hopfen);
    };

    const hopfenBeigabeDeleted = (hopen: HopfenbeigabeModel) => {
        const newHopfenbeigaben: HopfenbeigabeModel[] = [];

        if (hopen.id) {
            rezept.hopfenbeigaben?.forEach(item => {
                if (item.id === hopen.id) {
                    item.isDeleted = true;
                }
                newHopfenbeigaben.push(item);
            });
        }
        else {
            rezept.hopfenbeigaben?.forEach(item => {
                if (item !== hopen) {
                    newHopfenbeigaben.push(item);
                }
            });
        }
        
        setRezept({ ...rezept, hopfenbeigaben: newHopfenbeigaben });
    };

    const hopfenbeigabeCompleted = (hopfenbeigabe: HopfenbeigabeModel) => {
        console.log("Hopfenbeigabe created", hopfenbeigabe);
        setRezept({ ...rezept, hopfenbeigaben: [hopfenbeigabe, ...rezept.hopfenbeigaben || []] });
        setSelectedHopfen(undefined);
    };

    // Hefe
    const hefeSelected = (hefe: HefeModel) => {
        console.log("Hefe selected: ", hefe);
        setSelectedHefe(hefe);
    };

    const hefeBeigabeSelected = (hefe: HefebeigabeModel) => {
        console.log("Hefe selected: ", hefe);
    };

    const hefebeigabeCompleted = (hefebeigabe: HefebeigabeModel) => {
        console.log("Hefebeigabe created", hefebeigabe);
        setRezept({ ...rezept, hefebeigaben: [hefebeigabe, ...rezept.hefebeigaben || []] });
        setSelectedHefe(undefined);
    };

    // Rast
    const rastSelected = (rast: RastModel) => {
        console.log("Rast selected: ", rast);
    };

    const rastCompleted = (rast: RastModel) => {
        console.log("Rast created", rast);
        setRezept({ ...rezept, rasten: [rast, ...rezept.rasten || []].sort((a, b) => a.reihenfolge - b.reihenfolge) });
    };

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        setSaving(true);
        
        try {
            if (rezept.id) {
                // existing
                await axios.put(`/api/Rezept/${rezept.id}`, rezept);
                loadRezept();  // reload, so we get the new ids.
            }
            else {
                // new
                const result = await axios.post("/api/Rezept", rezept);
                setRezept(result.data);
                // todo: maybe redirect to have the id in the route. redirect without re-rendering?
            }
            setCurrentSuccess("Rezept gespeichert.");
        } catch (error) {
            console.error("Failed to submit rezept", error);
            setCurrentError("Rezept konnte nicht gespeichert werden.");
        } finally {
            setSaving(false);
        }

    };

    const loadBrauvorgaenge = async () => {
        if (!rezeptId && !rezept.id) return;

        setLoadingBrauvorgaenge(true);
        const result = await getData<BrauvorgangOverviewModel[]>(`/api/Brauvorgang/Rezept/${rezeptId || rezept.id}`);
        result.data ? setBrauvorgaenge(result.data) : setCurrentError(result.errorMessage);
        setLoadingBrauvorgaenge(false);
    };

    const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        console.log("currentTab", currentTab);
        if (currentTab === 1 && !brauvorgaenge) {
            loadBrauvorgaenge();
        }
    }, [currentTab]);

    const handleBrauvorgangSelected = (brauvorgang: BrauvorgangOverviewModel) => {
        history.push(`/brauvorgang/${brauvorgang.id}`);
    };

    return (
        <>
            <h1>{rezept.name ? `Rezept (${rezept.name})` : "Neues Rezept"}{loading && <CircularProgress size={24} />}</h1>

            <MessageProvider success={currentSuccess} onClear={() => setCurrentSuccess(undefined)} />
            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab label="Rezept" />
                    <Tab label="Brauvorgänge" />
                </Tabs>
            </Box>

            <TabPanel value={currentTab} index={0}>

                <Paper elevation={0} className={classes.containerPaper}>

                    {/* 
                    Die `id` der nachfolgenden `TextField` muss 1:1 dem Namen im `RezeptModel` entsprechen,
                    damit überall die gleiche `onChange`-Methode verwendet werden kann.
                    */}

                    <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
                        <RestrictedTextField
                            id="name"
                            label="Biername"
                            placeholder="Sommerbier"
                            required
                            variant="outlined"
                            value={rezept.name || ''}
                            onChange={handleFieldChange} />

                        <BierstilSelect id="bierstil" onBierstilSelected={onChangeBierstil} selectedBierstil={rezept.bierstil} isRequired={true} />

                        <VergaerungSelect value={rezept.vergaerung || ''} onValueSelected={handleChangeVergaerung} isRequired={true} />

                        <RestrictedTextField
                            id="alkoholgehalt"
                            label="Alkoholgehalt"
                            placeholder="6.8"
                            required
                            variant="outlined"
                            unit="Vol. %"
                            allowOnlyPositiveDecimals={true}
                            value={rezept.alkoholgehalt || ''}
                            onChange={handleFieldChange} />

                        <h4>Basisangaben</h4>

                        <Tooltip arrow title="Die Stammwürze hat die Masseinheit Grad Plato (°P), ist eine zentrale Messgrösse beim Bierbrauen und definiert den Dichtegehalt der Flüssigkeit vor der Vergärung. Ihr Hauptzweck dient dem späteren Bestimmen des Alkoholgehalts und Nährwert des fertigen Bieres. Der typische Wertbereich liegt bei 8-21 °P.">
                            <RestrictedTextField
                                id="stammwuerze"
                                label="Stammwürze"
                                placeholder="15.8"
                                required
                                variant="outlined"
                                unit="°P"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.stammwuerze || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="Der Endvergärungsgrad ist die Dichte der Flüssigkeit nach abgeschlossener Gärung. Der typische Wertbereich liegt bei 2-6 °P.">
                            <RestrictedTextField
                                id="endvergaerungsgrad"
                                label="Endvergärungsgrad"
                                placeholder="3.5"
                                required
                                variant="outlined"
                                unit="°P"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.endvergaerungsgrad || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="Fertigbier ist die Anzahl Liter Bier, welche am Schluss effektiv abgefüllt werden kann.">
                            <RestrictedTextField
                                id="fertigbier"
                                label="Fertigbier"
                                placeholder="48.3"
                                required
                                variant="outlined"
                                unit="Liter"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.fertigbier || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="Die Kochdauer sagt aus, wie lange das Bier gekocht werden muss. Im Normalfall beträgt diese 60 Minuten.">
                            <RestrictedTextField
                                id="kochdauer"
                                label="Kochdauer"
                                placeholder="60"
                                required
                                variant="outlined"
                                unit="Minuten"
                                allowOnlyPositiveNumbers={true}
                                value={rezept.kochdauer || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <h4>Mengenangaben</h4>

                        <Tooltip arrow title="Das Maischewasser sagt aus, wie viele Liter Wasser für den Maischevorgang zur Verfügung gestellt werden müssen. Beim Maischen wird das Malz mit Wasser vermischt und auf bei unterschiedlichen Temperaturen gehalten, damit Enzyme Stärke und Eiweisse abbauen können. Dadurch lösen sich verschiedene Zuckerarten, welche später durch die Hefe vergoren werden.">
                            <RestrictedTextField
                                id="maischewasser"
                                label="Maischewasser"
                                placeholder="25"
                                required
                                variant="outlined"
                                unit="Liter"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.maischewasser || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="Als Anschwänzwasser wird das Wasser bezeichnet, welches für den Läutervorgang verwendet wird. Der Läutervorgang findet nach dem Maischen statt und dient dem Zweck die Flüssigkeit von der Maische zu trennen mit dem Ziel, dass nur noch die Flüssigkeit übrig bleibt.">
                            <RestrictedTextField
                                id="anschwaenzwasser"
                                label="Anschwänzwasser"
                                placeholder="34"
                                required
                                variant="outlined"
                                unit="Liter"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.anschwaenzwasser || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="Die Kochmenge definiert wie viel Liter Bier gekocht werden muss und unterscheidet sich vom Fertigbier, da während des Kochens Flüssigkeit verdampft.">
                            <RestrictedTextField
                                id="kochmenge"
                                label="Kochmenge"
                                placeholder="58"
                                required
                                variant="outlined"
                                unit="Liter"
                                allowOnlyPositiveDecimals={true}
                                value={rezept.kochmenge || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <h4>Farbe / Bittere</h4>

                        <Tooltip arrow title="European Brewery Convention (EBC) ist eine Messgrösse für die Bierfarbe, welche in Europa verwendet wird. Der typische Wertbereich liegt bei 4-80 EBC.">
                            <RestrictedTextField
                                id="farbe"
                                label="Farbe"
                                placeholder="20"
                                variant="outlined"
                                unit="EBC"
                                allowOnlyPositiveNumbers={true}
                                value={rezept.farbe || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <Tooltip arrow title="International Bitterness Unit (IBU) ist eine Messgrösse für die Bitterkeit von Bier. Der typische Wertbereich liegt bei 10-95 IBU.">
                            <RestrictedTextField
                                id="bittere"
                                label="Bittere"
                                placeholder="16"
                                variant="outlined"
                                unit="IBU"
                                allowOnlyPositiveNumbers={true}
                                value={rezept.bittere || ''}
                                onChange={handleFieldChange} />
                        </Tooltip>

                        <TextField
                            id="beschreibung"
                            label="Beschreibung"
                            placeholder="Ein erfrischendes Sommerbier."
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={3}
                            value={rezept.beschreibung || ''}
                            onChange={handleFieldChange} />

                        <Button variant="contained" color="primary" size="large" disabled={saving} type="submit" className={classes.mr}>
                            <b>Speichern</b> {saving && <CircularProgress size={24} />}
                        </Button>

                        <Button variant="contained" color="secondary" size="large" disabled={rezept.id === undefined} onClick={() => setIsCreateBrauvorgangOpen(true)}>
                            Rezept brauen
                        </Button>
                    </form>

                    <h2>Malz <MalzSearchDialog onMalzSelected={malzSelected} /> </h2> <MalzbeigabeDialog onCompleted={malzbeigabeCompleted} malz={selectedMalz} />
                    <MalzbeigabeList onMalzSelected={malzBeigabeSelected} malz={rezept.malzbeigaben} />

                    <h2>Hopfen <HopfenSearchDialog onHopfenSelected={hopfenSelected} /></h2> <HopfenbeigabeDialog onCompleted={hopfenbeigabeCompleted} hopfen={selectedHopfen} />
                    <HopfenbeigabeList onHopfenSelected={hopfenBeigabeSelected} onHopfenDeleted={hopfenBeigabeDeleted} hopfen={rezept.hopfenbeigaben?.filter(item => !item.isDeleted)} />

                    <h2>Hefe <HefeSearchDialog onHefeSelected={hefeSelected} /></h2> <HefebeigabeDialog onCompleted={hefebeigabeCompleted} hefe={selectedHefe} />
                    <HefebeigabeList onHefeSelected={hefeBeigabeSelected} hefe={rezept.hefebeigaben} />

                    <h2>Rasten <RastDialog onCompleted={rastCompleted} reihenfolge={rezept.rasten?.length ? rezept.rasten.length + 1 : 1} /></h2>
                    <RastList onRastSelected={rastSelected} rast={rezept.rasten} />

                    <BrauvorgangDialog isOpen={isCreateBrauvorgangOpen} onOpenClose={(open) => setIsCreateBrauvorgangOpen(open)} rezeptId={rezept.id!} />
                </Paper>

            </TabPanel>

            <TabPanel value={currentTab} index={1}>
                <Alert severity="info">In dieser Ansicht sind alle Brauvorgänge ersichtlich, welche aus dem aktuellen Rezept erstellt wurden.</Alert>
                {loadingBrauvorgaenge ? <CircularProgress size={24} /> : <BrauvorgangList brauvorgaenge={brauvorgaenge} onBrauvorgangSelected={handleBrauvorgangSelected} />}
            </TabPanel>
        </>
    );
}