import { createStyles, makeStyles, MenuItem, TextField, Theme } from "@material-ui/core";
import axios from "axios";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { BierstilModel } from "../../../models/BrewAppModels";

interface BierstilProps extends InputHTMLAttributes<HTMLInputElement> {
    onBierstilSelected: (bierstil?: BierstilModel) => void;
    selectedBierstil?: BierstilModel;
    isRequired?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            minWidth: 220,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default function BierstilSelect(props: BierstilProps): React.ReactElement {

    const classes = useStyles();
    const [bierstil, setBierstil] = useState<BierstilModel[]>([]);

    const handleChangeBierstil = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.onBierstilSelected(bierstil.find(item => item.id === event.target.value));
    };

    useEffect(() => {
        async function getBierstile() {
            const result = await axios.get<BierstilModel[]>("/api/Bierstil");
            setBierstil(result.data);
        }
        getBierstile();
    }, []);

    return (
        <TextField
            select
            variant="outlined"
            className={classes.formControl}
            value={props.selectedBierstil?.id || ''}
            onChange={handleChangeBierstil}
            label="Bierstil"
            required={props.isRequired}
        >
            {bierstil?.map(item => <MenuItem value={item.id} key={item.id}>{item.bezeichnung}</MenuItem>)}
        </TextField>);
}