import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState, useEffect } from "react";

interface MessageProviderProps {
    error?: string;
    success?: string;
    onClear: () => void;
}

export default function MessageProvider(props: MessageProviderProps): React.ReactElement {

    const [hasMessage, setHasMessage] = useState(false);

    useEffect(() => setHasMessage(props.error || props.success ? true : false), [props.error, props.success]);

    useEffect(() => { !hasMessage && props.onClear() }, [hasMessage]);

    return (
        <Snackbar open={hasMessage} onClose={() => { setHasMessage(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000}>
            <Alert onClose={() => { setHasMessage(false) }} severity={props.error ? 'error' : 'success'}>
                { props.error ? <><b>Fehler</b> {props.error}</> : props.success }
            </Alert>
        </Snackbar>
    );
}