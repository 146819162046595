import { CircularProgress } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { RezeptOverviewModel } from "../../models/BrewAppModels";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";
import RezeptList from "../Rezept/RezeptList";

export default function MeineRezepte(): React.ReactElement {

    const [rezepte, setRezepte] = useState<RezeptOverviewModel[] | undefined>();
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const auth = useAuth();

    useEffect(() => {
        async function loadRezepte() {

            setLoading(true);
            const result = await getData<RezeptOverviewModel[]>("/api/Rezept");
            result.data ? setRezepte(result.data) : setCurrentError(result.errorMessage);
            setLoading(false);

        }
        
        if (auth.userData) {
            loadRezepte();
        }
        else {
            // Dieser Fall kann eintreten, wenn die Seite direkt durch die Eingabe
            // des URL aufgerufen wird, jedoch das SilentLogin noch nicht erfolgt ist.
            // Wir wissen jedoch noch nicht, ob das Login überhaupt erfolgreich sein wird.
            if (!auth.isLoading) setCurrentError("Es ist kein Benutzender angemeldet.");
        }

    }, [auth.userData, auth.isLoading]);

    const rezeptSelected = (rezept: RezeptOverviewModel) => {
        history.push(`/rezept/${rezept.id}`);
    };

    return (
        <>
            <h1>Meine Rezepte</h1>
            <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
            {loading ? <CircularProgress size={24} /> : <RezeptList onRezeptSelected={rezeptSelected} rezept={rezepte} /> }
        </>
    );
}