import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { useHistory } from "react-router";

interface CardButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    mainTitle: string;
    subTitle?: string;
    targetRoute?: string;
}

const useStyles = makeStyles({
    root: {
        width: 275,
        marginRight: '1em',
        marginBottom: '1em',
        cursor: 'Pointer',
        '&:hover': {
            background: "#eff0f1",
        },
    }
});

export default function CardButton(props: CardButtonProps): React.ReactElement {

    const classes = useStyles();
    const history = useHistory();

    return (
        <Card className={classes.root} variant="outlined" onClick={() => props.targetRoute && history.push(props.targetRoute)}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {props.mainTitle}
                </Typography>
                <Typography color="textSecondary">
                    {props.subTitle}
                </Typography>
            </CardContent>
        </Card>
    );
}
