import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { RezeptOverviewModel } from "../../models/BrewAppModels";

interface RezeptListProps extends InputHTMLAttributes<HTMLInputElement> {
    rezept?: RezeptOverviewModel[];
    onRezeptSelected: (rezept: RezeptOverviewModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function RezeptList(props: RezeptListProps): React.ReactElement {

    const handleRowClick = (rezeptId: number | undefined) => {

        if (rezeptId && props && props.rezept) {
            const rezept = props.rezept.find(item => item.id === rezeptId);
            if (rezept) props.onRezeptSelected(rezept);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Rezepttabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Vergärung</TableCell>
                        <TableCell>Stil</TableCell>
                        <TableCell>Alkoholgehalt</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rezept?.map((row) => (
                        <TableRow key={row.id} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.vergaerung}</TableCell>
                            <TableCell>{row.bierstilBezeichnung}</TableCell>
                            <TableCell>{row.alkoholgehalt}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}