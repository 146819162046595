import { Box, TextField, Typography } from "@material-ui/core";
import { ChangeEvent, useState, InputHTMLAttributes } from "react";
import { HefeModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import HefeList from "./HefeList";
import SearchButton from "../Common/SearchButton";
import { getData } from "../Common/AxiosWrapper";
import MessageProvider from "../Common/MessageProvider";

interface HefeSearchDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    onHefeSelected: (hefe: HefeModel) => void;
}

export default function HefeSearchDialog(props: HefeSearchDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [suchbegriff, setSuchbegriff] = useState('');
    const [hefeliste, setHefeliste] = useState<HefeModel[] | undefined>();
    const [loading, setLoading] = useState(false);
    const [currentError, setCurrentError] = useState<string | undefined>(undefined);

    const handleChangeSuchbegriff = (event: ChangeEvent<HTMLInputElement>) => {
        setSuchbegriff(event.target.value);
    };

    async function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setLoading(true);
        const result = await getData<HefeModel[]>(`/api/Hefe/${suchbegriff}`);
        result.data ? setHefeliste(result.data) : setCurrentError(result.errorMessage);
        setLoading(false);
    }

    const handleHefeSelected = (hefe: HefeModel) => {
        props.onHefeSelected(hefe);
        handleOpenClose(false);
    };

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
        if (!isOpen) {
            setSuchbegriff('');
            setHefeliste(undefined);
        }
    };

    return (
        <GenericFullDialog
            showButton={true}
            buttonTitle="Hefe suchen"
            title="Hefe suchen"
            isOpen={open}
            onOpenClose={handleOpenClose}
            bottomComponent={(hefeliste && hefeliste.length > 0) ? <HefeList hefe={hefeliste} onHefeSelected={handleHefeSelected} /> : <Typography>Keine Daten gefunden.</Typography>}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <MessageProvider error={currentError} onClear={() => setCurrentError(undefined)} />
                <TextField id="outlined-basic" label="Suchbegriff" variant="outlined" onChange={handleChangeSuchbegriff} size="small" autoFocus />
                <SearchButton loading={loading} enableString={suchbegriff} />
            </Box>}
        />

    );
}