import { Avatar, CircularProgress, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useState } from "react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
    }),
);

export default function AuthButton(): React.ReactElement {

    const auth = useAuth();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    let menuItems;

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const loginUser = () => {
        auth.userManager.signinRedirect();
    };

    const logoutUser = () => {
        auth.userManager.signoutRedirect();
    };

    const handleProfile = () => {
        window.location.href = '/Identity/Account/Manage';
    };

    if (auth.isLoading) {
        return <CircularProgress size={24} />;
    }

    if (auth.userData?.profile.name) {
        // user is logged in
        menuItems = (
            [
                <MenuItem key="menuUsername" onClick={handleCloseMenu} disabled={true}>{auth.userData?.profile.name}</MenuItem>,
                <MenuItem key="menuProfile" onClick={handleProfile}>Profil</MenuItem>,
                <MenuItem key="menuLogout" onClick={logoutUser}>Logout</MenuItem>
            ]
        );
    }
    else {
        // user is not logged in
        menuItems = (
            [
                <MenuItem key="menuLogIn" onClick={loginUser}>Log in</MenuItem>
            ]
        );
    }

    return (
        <>
            <IconButton aria-label="Account" color="inherit" onClick={handleMenuClick} edge="end">
                {auth.userData?.profile.name ? <Avatar className={classes.small}>{auth.userData?.profile.name.slice(0, 2)}</Avatar> : <AccountCircleIcon />}
            </IconButton>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {menuItems}
            </Menu>
        </>
    );

}