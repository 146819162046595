import { createStyles, InputAdornment, makeStyles, OutlinedTextFieldProps, TextField, Theme } from "@material-ui/core";
import { ChangeEvent, ForwardedRef, forwardRef } from "react";

interface RestrictedTextFieldProps extends OutlinedTextFieldProps {
    allowOnlyPositiveNumbers?: boolean;
    allowOnlyPositiveDecimals?: boolean;
    unit?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: '25ch',
        },
    })
);

// We need a forwardRef, so that the Tooltip works: https://v4.mui.com/components/tooltips/#custom-child-element
export default forwardRef(function RestrictedTextField({ allowOnlyPositiveNumbers, allowOnlyPositiveDecimals, unit, onChange, ...props }: RestrictedTextFieldProps, ref: ForwardedRef<HTMLDivElement>): React.ReactElement {

    const classes = useStyles();
    
    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {

        if (allowOnlyPositiveNumbers) {
            if (!(event.target.value === "" || /^\d+$/.test(event.target.value))) {
                return false;
            }
        }

        if (allowOnlyPositiveDecimals) {
            if (!(event.target.value === "" || /^\d+\.?\d{0,2}$/.test(event.target.value))) {
                return false;
            }
        }

        onChange(event);
    };

    return (
        <TextField
            {...props}
            ref={ref}
            InputProps={{
                endAdornment: unit && <InputAdornment position="end">{unit}</InputAdornment>,
            }}
            className={classes.textField}
            onChange={handleFieldChange} />
    );
})