import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import { HopfenModel } from "../../models/BrewAppModels";

interface HopfenListProps extends InputHTMLAttributes<HTMLInputElement> {
    hopfen?: HopfenModel[];
    onHopfenSelected: (hopfen: HopfenModel) => void;
}

const useStyles = makeStyles({
    tableRow: {
        cursor: 'Pointer'
    },
});

export default function HopfenList(props: HopfenListProps): React.ReactElement {

    const handleRowClick = (hopfenId: number | undefined) => {

        if (hopfenId && props && props.hopfen) {
            const hopfen = props.hopfen.find(item => item.id === hopfenId);
            if (hopfen) props.onHopfenSelected(hopfen);
        }

    };

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="Hopfentabelle">
                <TableHead>
                    <TableRow>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Art</TableCell>
                        <TableCell align="right">Alphasäuregehalt (%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.hopfen?.map((row) => (
                        <TableRow key={row.id} hover onClick={(event) => handleRowClick(row.id)} className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                                {row.bezeichnung}
                            </TableCell>
                            <TableCell>{row.hopfenArt}</TableCell>
                            <TableCell align="right">{row.alphasaeuregehalt}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

}