import { Box, Button } from "@material-ui/core";
import { ChangeEvent, useEffect, useState, InputHTMLAttributes } from "react";
import { MalzbeigabeModel, MalzModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import RestrictedTextField from "../Common/RestrictedTextField";

interface MalzbeigabeDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    malz?: MalzModel;
    onCompleted: (malzbeigabe: MalzbeigabeModel) => void;
}

export default function MalzbeigabeDialog(props: MalzbeigabeDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [malzbeigabe, setMalzbeigabe] = useState<MalzbeigabeModel>({} as MalzbeigabeModel);

    useEffect(() => {
        if (props.malz) {
            setOpen(true);
            setMalzbeigabe({ malz: props.malz } as MalzbeigabeModel);
        }
    }, [props.malz]);

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMalzbeigabe({ ...malzbeigabe, [event.target.id]: event.target.value });
    }

    function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        props.onCompleted(malzbeigabe);
        handleOpenClose(false);
    }

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
    };

    return (
        <GenericFullDialog
            title={`Malzbeigabe ${props.malz?.bezeichnung}`}
            isOpen={open}
            onOpenClose={handleOpenClose}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <RestrictedTextField
                    id="menge" 
                    label="Menge" 
                    variant="outlined" 
                    value={malzbeigabe.menge || ''}
                    onChange={handleFieldChange} 
                    size="small" 
                    unit="g"
                    allowOnlyPositiveNumbers={true}
                    required 
                    autoComplete="false"
                    autoFocus
                />
                <Button type="submit" variant="contained" color="primary" size="large">Übernehmen</Button>
            </Box>}
        />

    );

}