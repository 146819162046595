import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import './App.css';
import { AppBar, Box, Button, Container, createStyles, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer, Theme, Toolbar, Typography } from '@material-ui/core';
import { NavLink, Route, Switch } from "react-router-dom";
import logo from './logo.svg';
import Home from './components/Pages/Home';
import { AuthProvider, UserManager } from 'oidc-react';
import AuthButton from './components/Common/AuthButton';
import { SettingsContext } from './components/SettingsProvider';
import Oidc from 'oidc-client';
import axios from 'axios';
import Rezept from './components/Pages/Rezept';
import MeineRezepte from './components/Pages/MeineRezepte';
import Playground from './components/Pages/Playground';
import MenuIcon from '@material-ui/icons/Menu';
import MeineBrauvorgaenge from './components/Pages/MeineBrauvorgaenge';
import Brauvorgang from './components/Pages/Brauvorgang';
import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import RadioIcon from '@material-ui/icons/Radio';
import CreateIcon from '@material-ui/icons/Create';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import TimelineIcon from '@material-ui/icons/Timeline';
import Berechnungen from './components/Pages/Berechnungen';
import Auswertungen from './components/Pages/Auswertungen';

export default function App(): React.ReactElement {

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            menuButton: {
                marginRight: theme.spacing(1),
            },
            title: {
                flexGrow: 1,
            },
            applogo: {
                width: 40,
                height: 40,
            },
            applogosmall: {
                width: 30,
                height: 30,
            },
            media: {
                height: 100,
            },
            drawerlist: {
                width: 250,
            },
            imgResponsive: {
                width: '100%',
                height: 'auto'
            },
        }),
    );

    const classes = useStyles();

    const settingsContext = useContext(SettingsContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    console.log("==> Render App.tsx");

    Oidc.Log.logger = console;

    const userManager = useMemo(() => new UserManager(settingsContext!.oidcSettings!)
    , [settingsContext]);

    useEffect(() => {
        console.log("useEffect userManager");
        userManager.signinSilent()
            .then(() => setIsLoggedIn(true))
            .catch(err => console.log("There is no user to sign in.", err));
    }, [userManager]);


    // Add Bearer-Token to all requests
    axios.interceptors.request.use(async (config) => {
        
        const user = await userManager.getUser();

        if (user) {
            if (user.expired) {
                console.log("Axios interceptors: Token is expired");
                const silentUser = await userManager.signinSilent();
                console.log("Axios interceptors: signinSilent: Got new Token");
                config.headers.Authorization = `Bearer ${silentUser.access_token}`;
            }
            else {
                config.headers.Authorization = `Bearer ${user.access_token}`;
            }
        }

        return config;
    });
    
    // Mobile: settings menu
    const onMobileNavMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDrawerOpen(true);
    };

    let menuItems: ReactNode[] = [];
    let menuItemsMobileDrawer: ReactNode[] = [];

    if (isLoggedIn) {
        menuItems = (
            [
                <Button color="inherit" component={NavLink} key="rezept" exact to="/rezept">Rezept erfassen</Button>,
                <Button color="inherit" component={NavLink} key="meinerezepte" exact to="/meinerezepte">Meine Rezepte</Button>,
                <Button color="inherit" component={NavLink} key="meinebrauvorgaenge" exact to="/meinebrauvorgaenge">Brauvorgänge</Button>,
                <Button color="inherit" component={NavLink} key="berechnungen" exact to="/berechnungen">Berechnungen</Button>//,
                //<Button color="inherit" component={NavLink} key="auswertungen" exact to="/auswertungen">Auswertungen</Button>
            ]
        );

        menuItemsMobileDrawer = (
            [
                <ListItem button key="rezept" component={NavLink} exact to="/rezept">
                    <ListItemIcon><CreateIcon /></ListItemIcon>
                    <ListItemText primary="Rezept erfassen" />
                </ListItem>,
                <ListItem button key="meinerezepte" component={NavLink} exact to="/meinerezepte">
                    <ListItemIcon><FolderIcon /></ListItemIcon>
                    <ListItemText primary="Meine Rezepte" />
                </ListItem>,
                <ListItem button key="meinebrauvorgaenge" component={NavLink} exact to="/meinebrauvorgaenge">
                    <ListItemIcon><FolderIcon /></ListItemIcon>
                    <ListItemText primary="Meine Brauvorgänge" />
                </ListItem>,
                <ListItem button key="berechnungen" component={NavLink} exact to="/berechnungen">
                    <ListItemIcon><SwapVerticalCircleIcon /></ListItemIcon>
                    <ListItemText primary="Berechnungen" />
                </ListItem>//,
                // <ListItem button key="auswertungen" component={NavLink} exact to="/auswertungen">
                //     <ListItemIcon><TimelineIcon /></ListItemIcon>
                //     <ListItemText primary="Auswertungen" />
                // </ListItem>
            ]
        );
    }
    else {
        menuItemsMobileDrawer = (
            [
                <ListItem button key="login" onClick={() => userManager.signinRedirect()}>
                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                    <ListItemText primary="Login" />
                </ListItem>
            ]
        );
    }

    return (
        <AuthProvider autoSignIn={false} userManager={userManager}>
            <div className={classes.root}>
                
                {/* Desktop AppBar */}
                <Box display={{ xs: 'none', md: 'block' }}>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" component={NavLink} exact to="/">
                                <img src={logo} alt="BrewApp Logo" className={classes.applogo} />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                BrewApp
                            </Typography>
                            <Button key="home" color="inherit" component={NavLink} exact to="/">Home</Button>
                            {menuItems}
                            {/* <Button key="playground" color="inherit" component={NavLink} exact to="/playground">Playground</Button> */}
                            <AuthButton />
                        </Toolbar>
                    </AppBar>
                </Box>

                {/* Mobile AppBar */}
                <Box display={{ xs: 'block', md: 'none' }}>
                    <AppBar position="static">
                        <Toolbar variant="dense">
                            <IconButton edge="start" aria-label="display more actions" aria-controls="mobile-menu" aria-haspopup="true" color="inherit" onClick={onMobileNavMenuClick}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                BrewApp
                            </Typography>
                            <AuthButton />
                        </Toolbar>
                    </AppBar>
                </Box>

                <SwipeableDrawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
                    <div
                        className={classes.drawerlist}
                        role="presentation"
                        onClick={() => setDrawerOpen(false)}
                        onKeyDown={() => setDrawerOpen(false)}
                    >
                        <img src="/hopfen_sm.jpg" alt="BrewApp Hopfen" className={classes.imgResponsive} />
                        <List>
                            <ListItem button key="home" component={NavLink} exact to="/">
                                <ListItemIcon><HomeIcon /></ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItem>
                            {menuItemsMobileDrawer}
                            {/* <Divider />
                            <ListItem button key="playground" component={NavLink} exact to="/playground">
                                <ListItemIcon><RadioIcon /></ListItemIcon>
                                <ListItemText primary="Playground" />
                            </ListItem> */}
                        </List>
                    </div>
                </SwipeableDrawer>

                {/* Main Content */}
                <Container maxWidth="lg">
                    <Switch>
                        <Route path="/playground">
                            <Playground />
                        </Route>
                        <Route path={["/rezept/:rezeptId", "/rezept"]}>
                            <Rezept />
                        </Route>
                        <Route path="/meinerezepte">
                            <MeineRezepte />
                        </Route>
                        <Route path={["/brauvorgang/:brauvorgangId", "/brauvorgang"]}>
                            <Brauvorgang />
                        </Route>
                        <Route path="/meinebrauvorgaenge">
                            <MeineBrauvorgaenge />
                        </Route>
                        <Route path="/berechnungen">
                            <Berechnungen />
                        </Route>
                        <Route path="/auswertungen/:brauvorgangId">
                            <Auswertungen />
                        </Route>
                        <Route>
                            <Home />
                        </Route>
                    </Switch>
                </Container>

            </div>
        </AuthProvider>
    );
}
