import { UserManagerSettings } from "oidc-client";
import { createContext, ReactNode, useEffect, useState } from "react";
import axios from 'axios';

type SettingsContextType = {
    oidcSettings: UserManagerSettings | undefined;
};

interface IProps {
    children: ReactNode;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

function SettingsProvider({ children }: IProps): React.ReactElement {

    const [currentOidcSettings, setCurrentOidcSettingsToState] = useState<UserManagerSettings | undefined>();
    const [hasFailed, setHasFailed] = useState(false);

    useEffect(() => {
        console.log("Fetching settings");
        console.log("Base URL is: " + process.env.PUBLIC_URL);
        axios.get<UserManagerSettings>(`${process.env.PUBLIC_URL}/_configuration/BrewApp`)
            .then(result => {
                setCurrentOidcSettingsToState(result.data);
                console.log("Got settings");
            }).catch(e => {
                console.error("Failed to get settings", e);
                setHasFailed(true);
            });
    }, []);

    // https://reactjs.org/docs/concurrent-mode-suspense.html
    // https://dev.to/tappyy/oidc-authentication-with-react-identity-server-4-3h0d
    if (currentOidcSettings === undefined) {
        if (hasFailed) {
            return <h2>Failed to load settings...</h2>;
        }
        return <h2>Loading settings...</h2>;
    }

    return (
        <SettingsContext.Provider value={{ oidcSettings: currentOidcSettings }}>
            {children}
        </SettingsContext.Provider>
    );
}

export { SettingsProvider, SettingsContext };
