import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText, ListSubheader } from "@material-ui/core";
import { BrauschrittModel } from "../../models/BrewAppModels";
import DoneIcon from '@material-ui/icons/Done';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TimerResult } from "react-timer-hook";
import DisplayTime from "../Common/DisplayTime";

interface BrauschrittListItemProps {
    brauschritt: BrauschrittModel;
    showSubheader?: boolean;
    onClick: (id: number) => void;
    remainingTime?: TimerResult;
}

export default function BrauschrittListItem(props: BrauschrittListItemProps): React.ReactElement {

    const handleItemClick = (id: number) => {
        props.onClick(id);
    };

    const getRemainingTime = () => {
        if (props.remainingTime?.isRunning && props.brauschritt.status === "InBearbeitung") {
            return <>(Restdauer: <DisplayTime time={props.remainingTime} />)</>;
        }
        else {
            return <></>;
        }
    }

    const getIcon = (brauschritt: BrauschrittModel) => {
        switch (brauschritt.status) {
            case "Offen": return <></>
            case "InBearbeitung": return <ArrowForwardIcon />
            case "Abgeschlossen": return <DoneIcon />
            default: return <HelpOutlineIcon />
        }
    }

    return (
        <>
        { props.showSubheader &&
            <>
                <ListSubheader component="div">
                    {props.brauschritt.gruppe}
                </ListSubheader>
                <Divider />
            </>
        }
        <ListItem key={props.brauschritt.id} button onClick={(event) => handleItemClick(props.brauschritt.id!)}>
            <ListItemAvatar>
                <Avatar>
                    {getIcon(props.brauschritt)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={props.brauschritt.bezeichnung} 
                          secondary={props.brauschritt.vorgeseheneDauer ? <>Dauer: {props.brauschritt.vorgeseheneDauer} Minuten {getRemainingTime()}</> : ' '} />
        </ListItem>
        <Divider />
        </>
    );
}

