import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { BrauschrittModel } from "../../models/BrewAppModels";

interface BrauschrittDialogProps {
    onOpenClose: (open: boolean, closeBrauschritt: boolean) => void;
    isOpen: boolean;
    brauschritt: BrauschrittModel;
    isLoading: boolean;
}

export default function BrauschrittDialog(props: BrauschrittDialogProps): React.ReactElement {

    const handleClose = (closeBrauschritt: boolean) => {
        props.onOpenClose(false, closeBrauschritt);
    };

    const abschliessenDialog = () => {
        return (<>
                <DialogTitle id="alert-dialog-title">Brauschritt abschliessen</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Hiermit wird der Brauschritt &quot;{props.brauschritt.bezeichnung}&quot; abgeschlossen.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>
                        Abbrechen
                    </Button>
                    <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                        Abschliessen {props.isLoading && <CircularProgress size={24} />}
                    </Button>
                </DialogActions>
                </>
        );
    };

    const startenDialog = () => {
        return (<>
            <DialogTitle id="alert-dialog-title">Brauschritt starten</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Hiermit wird der Brauschritt &quot;{props.brauschritt.bezeichnung}&quot; gestartet.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>
                    Abbrechen
                </Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                    Starten {props.isLoading && <CircularProgress size={24} />}
                </Button>
            </DialogActions>
        </>
        );
    };

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={() => handleClose(false)}
            >
                 { props.brauschritt.status === "InBearbeitung" ?
                    abschliessenDialog() : props.brauschritt.status === "Offen" ?
                    startenDialog() : <></>
                 }
                 
            </Dialog>
        </div>
    );
}