import { Box, Button } from "@material-ui/core";
import { ChangeEvent, useEffect, useState, InputHTMLAttributes } from "react";
import { HefebeigabeModel, HefeModel } from "../../models/BrewAppModels";
import GenericFullDialog from "../Common/GenericFullDialog";
import RestrictedTextField from "../Common/RestrictedTextField";

interface HefebeigabeDialogProps extends InputHTMLAttributes<HTMLInputElement> {
    hefe?: HefeModel;
    onCompleted: (hefebeigabe: HefebeigabeModel) => void;
}

export default function HefebeigabeDialog(props: HefebeigabeDialogProps): React.ReactElement {

    const [open, setOpen] = useState(false);
    const [hefebeigabe, setHefebeigabe] = useState<HefebeigabeModel>({} as HefebeigabeModel);

    useEffect(() => {
        if (props.hefe) {
            setOpen(true);
            setHefebeigabe({ hefe: props.hefe } as HefebeigabeModel);
        }
    }, [props.hefe]);

    const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHefebeigabe({ ...hefebeigabe, [event.target.id]: event.target.value });
    }

    function submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        props.onCompleted(hefebeigabe);
        handleOpenClose(false);
    }

    const handleOpenClose = (isOpen: boolean) => {
        setOpen(isOpen);
    };

    return (
        <GenericFullDialog
            title={`Hefebeigabe ${props.hefe?.bezeichnung}`}
            isOpen={open}
            onOpenClose={handleOpenClose}
            topComponent={
            <Box component="form" onSubmit={submitForm}>
                <RestrictedTextField
                    id="menge"
                    label="Menge"
                    variant="outlined"
                    value={hefebeigabe.menge ||''}
                    onChange={handleFieldChange}
                    size="small"
                    unit="g"
                    allowOnlyPositiveDecimals={true}
                    required
                    autoComplete="false"
                    autoFocus
                />
                <Button type="submit" variant="contained" color="primary" size="large">Übernehmen</Button>
            </Box>}
        />

    );

}