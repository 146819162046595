import { Button, CircularProgress } from "@material-ui/core";
import { InputHTMLAttributes } from "react";
import SearchIcon from '@material-ui/icons/Search';

interface SearchButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    loading: boolean;
    enableString?: string;
}

export default function SearchButton(props: SearchButtonProps): React.ReactElement {
    return (
        <Button type="submit" variant="outlined" color="primary" size="large" disabled={!(props.enableString !== undefined && props.enableString.length > 0) || props.loading}><SearchIcon /> {props.loading && <CircularProgress size={24} />}</Button>
    );
}